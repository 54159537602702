import { AppBar, Toolbar, Box, useTheme, useMediaQuery, Tab, Link, Tabs, Typography } from '@mui/material';
import DrawerComponent from './DrawerComponent';
import React, { useState }  from 'react'

const styles = {
  toolbar: {
    backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)), url("/AppBarcompressed.jpg")',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    minHeight: 120
  }
};

const NavBar = () => {

    const isMatch = useMediaQuery(useTheme().breakpoints.down('md'));

    const [value, setValue] = useState(0); 

    const links = [
    { id: 'section1', label: 'HOME SELLERS' },
    { id: 'section2', label: 'HOME BUYERS' },
    { id: 'section3', label: 'CONTACT US'},
    ];

      const handleTabClick = (event, linksId) => {
        event.preventDefault();
        const section = document.getElementById(linksId);
        section.scrollIntoView({ behavior: 'smooth' });
    };

    const handleChange = (event, newValue) => { 
      setValue(newValue);
    };


  return (
    <AppBar position="static">
    { isMatch ? (
      <Toolbar style={styles.toolbar}>
          <img src="/hd-transparent.png" alt="logo" width={'40%'} height="40%" />
          <DrawerComponent 
          links={links} />
      </Toolbar>
    ) : (
       <Toolbar style={styles.toolbar}>
            <Box sx={{
              display: 'flex', 
              width: '100%', 
              alignItems: 'center',
              }}>
          <img src="/hd-transparent.png" alt="logo" width={'30%'} height="30%" />
          <Box display='flex' marginLeft={'auto'}>
                <Tabs 
                indicatorColor="none"
                component={Link} 
                value={value}
                onChange={handleChange}
                sx={{textDecoration: 'none'}}
                >
                    {links.map((link, index) => (
                    <Tab 
                    sx={{
                    color: '#eeb311',
                    fontWeight: 'bold', 
                    fontSize: '20px',
                    textDecoration: 'none',
                    ':hover': {
                        textDecoration: 'underline',
                        textUnderlineOffset: '5px',
                        },
                    '&.Mui-selected': { 
                      color: '#eeb311',
                     },
                    }}
                   key={link.id}
                   label={link.label}
                   href={`#${link.id}`}
                   onClick={(event) => handleTabClick(event, link.id)}
                    />
                    ))}
                </Tabs>
            </Box>
          </Box>
      </Toolbar>
    )}
    </AppBar>
  );
}

export default NavBar

