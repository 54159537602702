import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import PhoneInTalkRoundedIcon from '@mui/icons-material/PhoneInTalkRounded';
import SendIcon from '@mui/icons-material/Send';


function callNumber(number) {
  window.open(`tel:${number}`, "_self");
}

const CallToAction = () => {
  return (
    <Box 
    bgcolor='#121212'
    display='flex'
    flexDirection='column'
    textAlign='center' 
    alignItems='center' 
    sx={{justifyContent: 'center'}}
    >
     <Box width='80%'>
     <Typography
     fontFamily='Montserrat'
     color='white'
     fontWeight='bold'
     fontSize={{lg: 28, md: 24, sm: 20, xs: 15}}
     variant='h3'
     paddingTop={5}
     textAlign='center'
     >
        Solar Evaluation Services has over 20 Years of Technical Solar Experience.
     </Typography>
      <Box marginTop='15px'>
     <Typography
     fontFamily='Montserrat'
     color='white'
     fontWeight='bold'
     fontSize={{lg: 22, md: 22, sm: 20, xs: 15}}
     variant='6'
     textAlign='center'
     >
        We provide the information you need to get the best deal on your solar home sale or purchase.
     </Typography>
     </Box>
     </Box>
    <Box 
    display='flex'
    margin='auto'
    justifyContent='center'
    padding={5}
    >
       <Button
          sx={{ 
            ml: 2,
            backgroundColor: '#000', 
            color: 'white',
            borderColor: '#fff'
          }}
          variant="outlined"
          href={`mailto:chris@solarevaluationservices.com, dave@solarevaluationservices.com`}
          endIcon={<SendIcon />}>
          Email
        </Button>
          <Button 
          onClick={() => callNumber("480-712-0615")}
          sx={{ 
            ml: 2,
            backgroundColor: '#000', 
            color: 'white',
            borderColor: '#fff'
            }} variant="outlined" endIcon={<PhoneInTalkRoundedIcon />}>
          Call Us
        </Button>
    </Box>
    </Box>
  )
}

export default CallToAction;
