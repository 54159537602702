import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import CopyrightIcon from '@mui/icons-material/Copyright';

const CopyRight = () => {
  return (
    <Box
      padding={1}
      bgcolor='black'
      display="flex"
      flexDirection='row'
      justifyContent="space-evenly"
      alignItems="center"
      color='white'
      >
        <Typography>
         Copyright 2023 {<CopyrightIcon fontSize='sm' />} Solar Evaluation Services |  Built by JohnVernonWebDev
       </Typography>
    </Box>
  )
}

export default CopyRight
