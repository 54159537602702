import React from 'react'
import { Card, CardMedia, CardContent, Typography, Button, CardActionArea} from '@mui/material'
import { Box } from '@mui/system'
import MailRoundedIcon from '@mui/icons-material/MailRounded';
import { FaRegCircle, FaCircle } from 'react-icons/fa';

const ContentCard = () => {
  return (
    <Box
    backgroundColor='#080808'
     sx={{
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "center",
    gap: 5,
    padding: 3,
  }}
    >
       <Card 
       id='section1'
       sx={{ 
         maxWidth: 500,
         border: '3px solid transparent',
         backgroundColor: '#121212',
         borderRadius: '8px',
         overflow: 'hidden',
         borderRadius: '4px',
         border: '3px solid #38aa36'
         }}>
        <Box sx={{position: 'relative'}}>
        <CardMedia
          component="img"
          height="300"
          image="img5compressed.jpg"
          alt="home solar system"
          sx={{opacity: '0.9'}}
        />
        <Box
           sx={{
            textAlign: 'center',
            position: 'absolute',
            bottom: 0,
            width: '100%',
            bgcolor: 'rgba(18, 18, 18, .9)',
            color: 'white',
            p: 2,
            borderRadius: '4px',
          }}
          >
          <Typography 
          fontFamily='Montserrat' 
          color='white' 
          fontWeight='bold'
          fontSize={{lg: 29, sm: 25}}
          >
            HOME <span style={{color:'#38aa36'}}>SELLERS</span>
          </Typography>
        </Box>
        </Box>
        <CardContent>
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center', 
            mb: 2, 
            li: {
            color: '#38aa36',
            },
            }}>
          <Box sx={{width: '100%'}}>
          <ul>
            <li>
          <Typography  fontFamily='Roboto' variant="body1" align="center" sx={{ color: 'white', mb: 2 }}>
            Solar powered homes can sell for more than non-solar homes, but you'll need third-party help to validate your asking price.
          </Typography>
            </li>
            <li>
            <Typography  fontFamily='Roboto' variant="body1" align="center" sx={{ color:'white', mb: 2 }}>
            Without accurate system information it’s difficult to justify a higher asking price for the house.
            </Typography>
            </li>
            <li>
          <Typography  fontFamily='Roboto' variant="body1" align="center" sx={{color: 'white', mb: 2}}>
            We have over 20 years of experience in technical solar energy sales outlining the value of solar to homeowners in Arizona.
          </Typography>
            </li>
            <li>
            <Typography  fontFamily='Roboto' variant="body1" align="center" sx={{color: 'white'}}>
            Ask Solar Evaluation Services for a report on your solar system value, so that you don’t go into your solar home negotiation empty-handed.
            </Typography>
            </li>
          </ul>
        </Box>
        </Box>
        </CardContent>
       <CardActionArea sx={{ textAlign: 'center', marginTop: '-20px', marginBottom: '10px'}}>
        <Button 
        endIcon={<MailRoundedIcon />}
        href={`mailto:chris@solarevaluationservices.com, dave@solarevaluationservices.com`}
        variant="outlined" 
        color="inherit" 
        sx={{ 
          color: '#fff', 
          borderColor: '#fff',
          '&:hover': {
          borderColor: '#1976d2'
          }
          }}>
          Learn More
        </Button>
      </CardActionArea>
    </Card>
    <Card 
    id='section2'
    sx={{ 
      maxWidth: 500,
      border: '3px solid transparent',
      backgroundColor: '#121212',
      borderRadius: '8px',
      overflow: 'hidden',
      borderRadius: '4px',
      border: '3px solid #eeb311'
      }}>
       <Box sx={{position: 'relative'}}>
        <CardMedia
          component="img"
          height="300"
          image="img9compressed.jpeg"
          alt="Home Solar System"
          sx={{opacity: '0.7'}}
        />
          <Box
           sx={{
            textAlign: 'center',
            position: 'absolute',
            bottom: 0,
            width: '100%',
            bgcolor: 'rgba(18, 18, 18, .9)',
            color: 'white',
            p: 2,
            borderRadius: '4px',
          }}
          >
          <Typography 
          fontFamily='Montserrat' 
          color='white' 
          fontWeight='bold'
          fontSize={{lg: 29, sm: 25}}
          >
            HOME <span style={{color:'#eeb311'}}>BUYERS</span>
          </Typography>
        </Box>
        </Box>
        <CardContent>
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center', 
            mb: 2,
            li: {
            color:'#eeb311',
            }
            }}>
           <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <ul>
            <li>
          <Typography  fontFamily='Roboto' variant="body1" align="center" sx={{ color:'white', mb: 2 }}>
            Buying a solar-powered home can be less expensive than paying for solar to be installed on the home you own. 
          </Typography>
            </li>
            <li>
               <Typography  fontFamily='Roboto' variant="body1" align="center" sx={{ color:'white', mb: 2 }}>
              Many older utility plans for solar are more favorable, saving more per month. The solar system needs to function correctly, and it should still have active warranties.
              </Typography>
            </li>
            <li>
          <Typography  fontFamily='Roboto' variant="body1" align="center" sx={{ color:'white', mb: 2}}>
            Solar Evaluation Services provides a report so that you know how much money the solar system will save you. 
          </Typography>
          </li>
          <li>
             <Typography  fontFamily='Roboto' variant="body1" align="center" sx={{ color:'white'}}>
            We evaluate your prospective home and it’s worth; finding and reporting any problems with the system, before moving in.
            </Typography>
          </li>
          </ul>
          </Box>
        </Box>
        </CardContent>
      <CardActionArea sx={{ textAlign: 'center', marginTop: '0px', marginBottom: '10px' }}>
        <Button 
        endIcon={<MailRoundedIcon />}
        href={`mailto:chris@solarevaluationservices.com, dave@solarevaluationservices.com`} 
        variant="outlined" 
        color="inherit" 
        sx={{ 
          color: '#fff', 
          borderColor: '#fff',
          '&:hover': {
          borderColor: '#1976d2'
          }
          }}>
          Learn More
        </Button>
      </CardActionArea>
    </Card>
    </Box>
  )
}

export default ContentCard
