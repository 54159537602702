import { Box, useTheme, useMediaQuery } from '@mui/material'
import React from 'react'

const Footer = () => {
    const isMatch = useMediaQuery(useTheme().breakpoints.down('md'));
  return (
    <Box 
    bgcolor='#111'
    display='flex'
    flexDirection='column'
    justifyContent='center'
    alignItems='center'
    >
        <img 
        src="/hd-transparent.png" 
        alt="logo" 
        width= {isMatch ? '60%' : '40%'}
        />
    </Box>
  )
}

export default Footer