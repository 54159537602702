const warrantyYears = 25;            //total years a system is expected to last
const lockinPeriodPre2018= 20;       //years which a system operates before rate changes apply
const lockinPeriodAfter2018 = 10;

export const panelRanges = {
    a: {min: 10, max: 20},
    b: {min: 20, max: 30},
    c: {min: 30, max: 40},
    d: {min: 40, max: 50},
    e: {min: 50, max: 60},
}


const panelWattageByYear = {
    2013: {min: 200, max: 250},
    2014: {min: 200, max: 250},
    2015: {min: 220, max: 270},
    2016: {min: 240, max: 290},
    2017: {min: 260, max: 310},
    2018: {min: 280, max: 330},
    2019: {min: 300, max: 350},
    2020: {min: 320, max: 370},
    2021: {min: 340, max: 390},
    2022: {min: 360, max: 410},
    2023: {min: 380, max: 430},
}

const kwhRate= 0.19;
const netExportRates = {
    2018: 0.1290,
    2019: 0.1161,
    2020: 0.1045,
    2021: 0.1045,
    2022: 0.09405,
    2023: 0.08463,
};
const currentYear= new Date().getFullYear();



export const calculateEnergyProductionRange = (year, panelCount) => {
    const lowRangeMultiplier= 1.2;
    const highRangeMultiplier= 1.7;

    const panelRange= panelRanges[panelCount];
    const panelWattage= panelWattageByYear[year];

    const lowProductionEstimate= panelRange.min * panelWattage.min * lowRangeMultiplier;
    const highProductionEstimate= panelRange.max * panelWattage.max * highRangeMultiplier;

    return { 
        lowProductionEstimate,
        highProductionEstimate
    };
}

export const calculateYearsRemaining = (systemYear, currentYear) => {
    const systemAge = currentYear - systemYear;      //2024 - 2013 = 11         
    const yearsRemaining= systemYear + warrantyYears - currentYear;

    //never return negative numbers
    if(yearsRemaining <= 0){
        return {
            yearsRemaining: 0,
            firstLifeRemaining: 0,
            secondLifeRemaining: 0,
        }
    }

    // pre-2018 systems have 20+5 lifetimes; 2018 and after has 10+15 lifetimes;
    const breakpoint = systemYear < 2018 ? lockinPeriodPre2018 : lockinPeriodAfter2018;

    const firstLifeRemaining = systemAge > breakpoint
                                ? 0
                                : systemYear + breakpoint - currentYear;
    const secondLifeRemaining = yearsRemaining - firstLifeRemaining

    

    return {
        yearsRemaining,
        firstLifeRemaining,
        secondLifeRemaining
    }
}


/**
 * 
 * 
 * 
 * @returns isEarlySystem: boolean          // if system is older than 2018
 * @returns yearsRemaining: number          // system life expectency remaining (20 years max)
 * @returns firstDecadeRemaining: number    // years left before system is 10 years old
 * @returns secondDecadeRemaining: number   // years left in system's second decade of life
 * @returns minSavings: number              // estimated savings at minimum productivity
 * @returns maxSavings: number              // estimated savings at maximum productivity
 */ 
export function calculateSavings(systemYear, panelCount) {
    console.log(`calculating...`)

    const {yearsRemaining, firstLifeRemaining, secondLifeRemaining} = calculateYearsRemaining(systemYear, currentYear);

    let isEarlySystem= false;
    let minSavings= 0, maxSavings= 0;

    //const firstLifeRemaining= (systemYear + warrantyYears) -  (currentYear + breakpoint);

    /*
    let firstDecadeRemaining= 0,
        secondDecadeRemaining= 0;

    
    if(yearsRemaining > breakpoint){
        firstDecadeRemaining = yearsRemaining - breakpoint;
        secondDecadeRemaining = warrantyYears - breakpoint;

        console.log(`more than ${breakpoint} years remaining. ${yearsRemaining}. [${firstDecadeRemaining}, ${secondDecadeRemaining}]`)
    } 
    else {
        console.log(`less than ${breakpoint} years remaining. ${yearsRemaining}`)
        firstDecadeRemaining = 0;
        secondDecadeRemaining = yearsRemaining;
    }*/

    const { lowProductionEstimate, highProductionEstimate } = calculateEnergyProductionRange(systemYear, panelCount);
    
    if(systemYear < 2018){
        isEarlySystem = true;
        console.log(`EARLY System (${systemYear}) has ${yearsRemaining}`);
        const {
            firstLifeMultiple,
            secondLifeMultiple
        } = savingsBefore2018(firstLifeRemaining, secondLifeRemaining);

        console.log(`${systemYear} [${firstLifeMultiple}, ${secondLifeMultiple}]`);
        minSavings = Math.round(lowProductionEstimate * (firstLifeMultiple + secondLifeMultiple));
        maxSavings = Math.round(highProductionEstimate * (firstLifeMultiple + secondLifeMultiple));
        console.log(`    min: ${lowProductionEstimate} => ${minSavings}`);
        console.log(`    max: ${highProductionEstimate} => ${maxSavings}`);
        /*
        const multiple = yearsRemaining * kwhRate;
        minSavings= Math.round(multiple * lowProductionEstimate);
        maxSavings= Math.round(multiple * highProductionEstimate);
        */

    }
    else {
        isEarlySystem = false;
        console.log(`NEWER System (${systemYear}) has ${yearsRemaining} - [${firstLifeRemaining}, ${secondLifeRemaining}]`);

        const { 
            firstDecadeMultiple, 
            secondDecadeMultiple
        } = savingsAfter2018(systemYear, firstLifeRemaining, secondLifeRemaining);


        minSavings = Math.round(lowProductionEstimate * (firstDecadeMultiple + secondDecadeMultiple));
        maxSavings = Math.round(highProductionEstimate * (firstDecadeMultiple + secondDecadeMultiple));
    }
    
    const  exportRate= systemYear >= 2018 ? netExportRates[systemYear] : kwhRate;
    
    const dollarFormatter = new Intl.NumberFormat('en-us', {
        style: 'currency',
        currency: 'USD'
    });

    return {
        isEarlySystem,
        yearsRemaining,
        firstLifeRemaining,
        secondLifeRemaining,
        lowProductionEstimate,
        highProductionEstimate,
        minSavings: dollarFormatter.format(minSavings),
        maxSavings: dollarFormatter.format(maxSavings),
        exportRate
    }
}

function savingsBefore2018(firstLifeRemaining, secondLifeRemaining) {
    const firstLifeMultiple= firstLifeRemaining * kwhRate;
    const secondLifeMultiple= (secondLifeRemaining * 0.5 * kwhRate) + (secondLifeRemaining * 0.5 * 0.05);

    return {
        firstLifeMultiple,
        secondLifeMultiple
    }
}



function savingsAfter2018(startingYear, firstDecadeRemaining, secondDecadeRemaining) {

    //yf * 0.5 * (0.19 + x)
    const firstDecadeMultiple= firstDecadeRemaining * 0.5 * (netExportRates[startingYear] + kwhRate);
    console.log(`  fdm: ${firstDecadeRemaining} * 0.5 * (${netExportRates[startingYear]} + ${kwhRate}) = ${firstDecadeMultiple}`)

    //fs * 0.5 * 0.24
    const secondDecadeMultiple= secondDecadeRemaining * 0.12;
    console.log(`  sdm: ${secondDecadeRemaining} * 0.5 * 0.24 = ${secondDecadeMultiple}`);

    return {
        firstDecadeMultiple,
        secondDecadeMultiple
    }
}
