import { Drawer, IconButton, List, ListItemButton, ListItemText } from '@mui/material'
import React, { useState } from 'react'
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import CloseIcon from '@mui/icons-material/Close';

const DrawerComponent = ({ links }) => {
    const [open, setOpen] = useState(false)

  return (
    <React.Fragment>
    <Drawer
    open={open} 
    onClose={() => setOpen(false)}
    sx={{
          '& .MuiDrawer-paper': {
            backgroundColor: '#121212',
            position: 'absolute'
          }
        }}
    >
        <List>
       {links.map((item) => (
    <ListItemButton
     onClick={() => setOpen(false)}
     key={item.id}
     component="a"
     href={`#${item.id}`}
     sx={{
           color: '#eeb311'
        }}
    >
    <ListItemText>{ item.label }</ListItemText>
    </ListItemButton>
        ))}
        </List>
    </Drawer>
    <IconButton 
        onClick={() => setOpen(!open)}
        sx={{ ml: 'auto', color: '#eeb311' }}
      >
        {open ? <CloseIcon /> : <MenuRoundedIcon fontSize='large' />}
      </IconButton>
    </React.Fragment>
  )
}

export default DrawerComponent;
