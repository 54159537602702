import React, { useState } from 'react';
import { Box, MenuItem, FormControl, Select, Button } from '@mui/material';

const panelOptions= {
    a: '10-20',
    b: '20-30',
    c: '30-40',
    d: '40-50',
    e: '50-60',
}

const now = new Date();
const yearOptions = [];
for(let i= 2013; i <= now.getFullYear(); i++){
    yearOptions.push(i);
}


const CalculatorForm = ({onSubmit}) => {
    const [year, setYear]= useState(2013);
    const [panels, setPanels]= useState('a');
    const [utility, setUtility]= useState('APS');

    const onChangeYear = (event) => {
        const year= event.target.value;
        setYear(Number.parseInt(year))
    }
    
    const onChangePanels = (event) => {
        const panels= event.target.value;
        setPanels(panels)
    }

    const onChangeUtility = (event) => {
        const utility= event.target.value;
        setUtility(utility)
    }

    const handleSubmitEstimate = () => {
        console.log(`handle submit ${year}, ${panelOptions[panels]}`)
        if(year && panels){
            console.log(`- calling props.onSubmit`)
            onSubmit(year, panels, panelOptions[panels]);
        }
    }


    return (
        <Box
            display='flex'
            flexDirection={{md: 'row', xs: 'column'}}
            alignItems="center"
            justifyContent='space-between'
            color='white'
        >
            <Box
                justifyContent={'flex-end'}
            >
                <FormControl 
                    variant="standard" 
                    sx={{ 
                        m: 1, 
                        display: {xs: 'inline', md: 'flex'},
                        //flexGrow: 2,
                    }}
                >
                    Installation Year:&nbsp;
                    <Select
                        labelId="select-year-label"
                        id="select-year"
                        value={year}
                        onChange={onChangeYear}
                        sx= {{
                            color: 'white',
                            textAlign: 'right',
                            '&:before': {
                                borderColor: 'white',
                            },
                            '&:after': {
                                borderColor: 'white',
                            },
                            '&:not(.Mui-disabled):hover::before': {
                                borderColor: 'white',
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'white'
                            },
                            '& .MuiSvgIcon-root': {
                                color: 'white'
                            }
                        }}
                    >
                        {yearOptions.map(year=>(
                            <MenuItem sx={{ justifyContent: "center", width: 'auto' }} color="white" value={year}>{year}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>

            <Box
                justifyContent={'flex-end'}
            >
               <FormControl 
                    variant="standard" 
                    sx={{ 
                        m: 1, 
                        display: {xs: 'inline', md: 'inline-flex'}, 
                        //flexGrow: 2,
                    }}
                >
                    Number of panels:&nbsp;
                    <Select 
                        value={panels}
                        onChange={onChangePanels}
                        sx= {{
                            color: 'white',
                            textAlign: 'right',
                            '&:before': {
                                borderColor: 'white',
                            },
                            '&:after': {
                                borderColor: 'white',
                            },
                            '&:not(.Mui-disabled):hover::before': {
                                borderColor: 'white',
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'white'
                            },
                            '& .MuiSvgIcon-root': {
                                color: 'white'
                            }
                        }}
                    >
                        {Object.entries(panelOptions).map(([k,v])=>(
                            <MenuItem sx={{ justifyContent: 'center'}} color="white" value={k}>{v}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
            <Box
                justifyContent={'flex-end'}
            >
                <FormControl 
                    variant="standard" 
                    sx={{ 
                        m: 1, 
                        display: {xs: 'inline', md: 'flex'},
                        //flexGrow: 2,
                    }}
                >

                    Utility Provider:&nbsp;
                    <Select 
                        value={utility}
                        onChange={onChangeUtility}
                        sx= {{
                            color: 'white',
                            textAlign: 'right',
                            '&:before': {
                                borderColor: 'white',
                            },
                            '&:after': {
                                borderColor: 'white',
                            },
                            '&:not(.Mui-disabled):hover::before': {
                                borderColor: 'white',
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'white'
                            },
                            '& .MuiSvgIcon-root': {
                                color: 'white'
                            }
                        }}
                    >
                        <MenuItem sx={{ justifyContent: 'center'}} color="white" value='APS'>APS</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            
            
            <Button 
                variant="outlined" 
                onClick={handleSubmitEstimate}
                sx={{
                    borderColor: "white",
                    borderWidth: 2,
                    color: 'white',
                    '&:hover': {
                        borderColor: 'white',
                        color: 'white',
                        borderWidth: 2,
                        backgroundColor: 'rgba(255,255,255, 0.5)',
                    }
                }}
            >Estimate My Savings</Button>
        </Box>
    )
}

export default CalculatorForm;