import React, { useState, useEffect } from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FaRegCircle, FaCircle } from 'react-icons/fa';

const Carousel = () => {
  const isMatch = useMediaQuery(useTheme().breakpoints.down('sm'));
  const [activeIndex, setActiveIndex] = useState(0);
  const items = [
    'Installing solar power on a home isn’t cheap, which is one of the reasons people opt to buy a home with solar installed on it already.',
    'Whether you’re a home seller or buyer, it’s important that you get the best deal when buying or selling a solar-powered home.',
    'Home sellers can recoup some of their investment, and home buyers can get a solar system for less when they buy a home that already has solar installed.'
  ];
   const icons = items.map((_, index) => (
    activeIndex === index ? <FaCircle key={index} /> : <FaRegCircle key={index} />
  ));

  useEffect(() => {
    const intervalId = setInterval(() => {
      setActiveIndex((activeIndex + 1) % items.length);
    }, 7000);

    return () => clearInterval(intervalId);
  }, [activeIndex, items.length]);

  return (
    <Box sx={{ position: 'relative', height: '200px' }}>
      {items.map((item, index) => (
        <Typography
          padding={2}
          fontFamily='Roboto'
          key={index}
          variant="body1"
          align="center"
          sx={{
            fontSize: {
              xs: '1rem',
              sm: '1.2rem',
              md: '1.5rem',
            },
            color: '#fff',
            mt: 2
          }}
          style={{ display: activeIndex === index ? 'block' : 'none' }}
        >
          {item}
        </Typography>
      ))}
      <Box sx={{ display: 'flex', justifyContent: 'center', position: 'absolute', bottom: '0', left: '0', right: '0', margin: 'auto', padding: isMatch ? 0 : 7, fontSize: '1rem', color: "#f8e09e" }}>
        {icons}
      </Box>
    </Box>
  );
};

export default Carousel;


