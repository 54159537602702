import React, { useState } from 'react'
import { FormLabel, Box, MenuItem, Select, TextField, Button, Checkbox, FormControlLabel, FormGroup, FormControl, useMediaQuery, useTheme, Typography } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const FormInput = () => {

  const isMatch = useMediaQuery(useTheme().breakpoints.down('sm'));


  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    role: "",
    offer: "",
    listed: "",
    checkedEmail: true,
    checkedPhone: false,
    checkedText: false,
  });

  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleFormSubmit = async (formData) => {
  await fetch(
  `${process.env.REACT_APP_URL}/.netlify/functions/emails/contact`,
  {
    headers: {
        "netlify-emails-secret": process.env.REACT_APP_NETLIFY_EMAILS_SECRET,
    },
    method: "POST",
    body: JSON.stringify({
      from: "info@solarevaluationservices.com",
      to: ["chris@solarevaluationservices.com", "dave@solarevaluationservices.com"],
      subject: "Solar report request",
      parameters: {
        formData: formData
      },
    }),
  }
);
}

  const handleChecked = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.checked,
    });
  };

  const handleChange = (e) => {
  const { name, value, type, checked } = e.target;

  if (type === 'checkbox') {
    setFormData((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  } else {
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }
};

  const handleSubmit = async(e) => {
    e.preventDefault();
    await handleFormSubmit(formData);
    setIsSubmitted(true); 
  }

  const { checkedEmail, checkedPhone, checkedText } = formData;

  return (
    <Box
    id="section3"
    backgroundColor='#080808'
    onSubmit={handleSubmit}
    >
      <Typography
      padding={3}
      variant='h3'
      fontWeight='bold'
      fontFamily='Montserrat'
      color='#38aa36'
      fontSize={{lg: 30, md: 24, sm: 20, xs: 18}}
      >
        Contact us for your Solar Report
      </Typography>
    <Box
    backgroundColor='#F0F0F0'
    component='form'
    sx={{
      width: isMatch ? 350 : 500, 
      display: 'flex',
      justifyContent: 'center', 
      alignItems: 'center',
      flexDirection: 'column',
      borderRadius: '4px',
      border: '6px solid black',
      borderImage: 'linear-gradient(to bottom right, #8bda8a, #080808, #f8e09e) 1',
      borderImageSlice: 1,
      minHeight: '75vh',
      margin: '0 auto',
      mb: 2
      }}
    >
      <TextField 
      required
      id='outlined-required'
      label='Name'
      name='name'
      value={formData.name}
      onChange={handleChange}
      type={'text'} 
      sx={{margin: 3, width: isMatch ? '300px' : '450px'}} 
      placeholder='Name' 
      />
      <TextField 
      required
      id='outlined-required'
      label='Email'
      name='email'
      value={formData.email}
      onChange={handleChange}
      type={'email'} 
      sx={{margin: 3, width: isMatch ? '300px' : '450px'}} 
      placeholder='Email' 
      />
      <TextField 
      id='outlined'
      label='Phone'
      name='phone'
      value={formData.phone}
      onChange={handleChange}
      type={'text'} 
      sx={{margin: 3, width: isMatch ? '300px' : '450px'}}
      placeholder='Phone Number'  
      />
      <TextField 
      id='outlined'
      label='Address'
      name='address'
      value={formData.address}
      onChange={handleChange}
      type={'text'} 
      sx={{margin: 3, width: isMatch ? '300px' : '450px'}}
      placeholder='Home Address'  
      />

      <FormControl sx={{ width: isMatch ? 300 : 450 }}>
        <FormLabel sx={{ textAlign: 'center' }} component="legend">Are you Buying or Selling?</FormLabel>
          <Select
          name='role'
          value={formData.role || ''} 
          onChange={handleChange}
          displayEmpty
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <em>Select an option</em>;
            }
              return selected;
          }}
          inputProps={{
          name: 'role',
          id: 'role-select',
          }}
          >
          <MenuItem value='buyer'>Buying</MenuItem>
          <MenuItem value='seller'>Selling</MenuItem>
        </Select>
      </FormControl>
        
        {formData.role === 'seller' && (
      <FormControl sx={{ width: isMatch ? 300 : 450 }}>
        <FormLabel sx={{ textAlign: 'center' }} component="legend">Have you listed the Home for sale?</FormLabel>
         <Select
          name='listed'
          value={formData.listed}
          onChange={handleChange}
          displayEmpty
          renderValue={(selected) => {
            if (selected.length === 0) {
            return <em>Select an option</em>;
          }
          return selected ? 'Yes' : 'No';
          }}
          inputProps={{
            name: 'listed',
            id: 'listed-select',
          }}
        >
    <MenuItem value={true}>Yes</MenuItem>
    <MenuItem value={false}>No</MenuItem>
  </Select>
      </FormControl>
        )}
      
      {formData.role === 'buyer' && (
    <FormControl sx={{ width: isMatch ? 300 : 450  }}>
      <FormLabel sx={{ textAlign: 'center' }} component="legend">Have you made an offer on the Home?</FormLabel>
        <Select
          name='offer'
          value={formData.offer}
          onChange={handleChange}
          displayEmpty
          renderValue={(selected) => {
            if (selected.length === 0) {
            return <em>Select an option</em>;
          }
            return selected ? 'Yes' : 'No';
        }}
          inputProps={{
            name: 'offer',
            id: 'offer-select',
        }}
      >
        <MenuItem value={true}>Yes</MenuItem>
        <MenuItem value={false}>No</MenuItem>
      </Select>
    </FormControl>
      )}

    <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
        <FormLabel sx={{ textAlign: 'center' }} component="legend">Preferred Method of Contact</FormLabel>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox checked={checkedEmail} onChange={handleChecked} name="checkedEmail" />
            }
            label="Email"
          />
          <FormControlLabel
            control={
              <Checkbox checked={checkedPhone} onChange={handleChecked} name="checkedPhone" />
            }
            label="Phone Call"
          />
          <FormControlLabel
            control={
              <Checkbox checked={checkedText} onChange={handleChecked} name="checkedText" />
            }
            label="Text"
          />
        </FormGroup>
      </FormControl>
        {isSubmitted ? (
           <CheckCircleIcon style={{ color: 'green' }} />
        ) : (
          <Button type='submit'>submit</Button>
        )}
    </Box>
    </Box>
  )
}

export default FormInput
