import React from 'react'
import { Card, CardMedia, CardContent, Typography, useMediaQuery, useTheme } from '@mui/material'
import { Box } from '@mui/system'
import Carousel from './Carousel'
import Calculator from '../Calculator/Calculator'

const HeaderCard = () => {
  const isMatch = useMediaQuery(useTheme().breakpoints.down('md'));
  return (
    <Box
      backgroundColor='#080808'
      sx={{
        display: 'flex',
        flexWrap: 'wrap'
      }}
      textAlign='left'
      flexDirection='row'
      justifyContent='center'
      gap={5}
    >
       <Card 
       sx={{
         maxWidth: '100%',
         minWidth: '50%',
         backgroundColor: '#121212',
         borderRadius: 0,
         //border: '6px solid black',
         //borderImage: 'linear-gradient(to bottom right, #8bda8a, #080808, #f8e09e) 1',
         //borderImageSlice: 1
        }}>
          <Box sx={{position: 'relative'}}>
        <CardMedia
          component="img"
          //height={isMatch ? '700' : '600'}
          image="/img3-touchup3-md.jpg"
          alt="Home Solar System"
          sx={{ opacity: 0.8}}
        />
            <Box
            sx={{
              position: 'absolute',
              top: isMatch ? '8%' : '14%',
              left: '50%',
              width: isMatch ? '75%' : '75%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'rgba(0, 0, 0, 1, 0)',
              color: 'white',
              p: 2,
              borderRadius: '4px',
            }}
            >
            <Typography
              fontFamily='Montserrat'
              fontSize={{ lg: 36, md: 39, sm: 22, xs: 18 }}
              fontWeight='bold'
              color='black'
              textAlign='right'
            >
              SOLAR SYSTEM EVALUATIONS
            </Typography>
            <Typography
              fontFamily='Montserrat'
              fontSize={{ lg: 30, md: 28, sm: 20, xs: 16 }}
              fontWeight='bold'
              color='black'
              textAlign='right'
              
            >
              <span style={{ fontSize: '0.6em'}}>for</span>&nbsp;RESIDENTIAL PROPERTIES <span style={{ fontSize: '0.6em'}}>in</span>&nbsp;ARIZONA.
            </Typography>
            </Box>
            <Box
              sx={{
                position: 'absolute',
                top: isMatch ? '18%' : '30%',
                left: isMatch ? '4%' :'10%',
                width: isMatch ? '92%' : '80%'
              }}
              >
              <Calculator />
            </Box>
        </Box>
          <CardContent>
            <Box sx={{width: '100%'}}>
              <Carousel />
            </Box>
      </CardContent>
     </Card>
    </Box>
  )
}

export default HeaderCard
