import { useState } from 'react';
import { calculateSavings } from './helpers';
import CalculatorForm from './CalculatorForm';
import SubmissionForm from './SubmissionForm';
import { Box, Typography, CircularProgress } from '@mui/material';

const initialSystemLifeSpan = {
    totalYears: 0,
    firstLife: 0,
    secondLife: 0
};

const EMAIL_STATUS_SENDING= 'SENDING'
const EMAIL_STATUS_SUCCESS= 'SUCCESS'
const EMAIL_STATUS_FAILURE= 'FAILURE'

const SolarSavingsCalculator = () => {
    const [year, setYear] = useState();
    const [panels, setPanels] = useState();
    const [minSavings, setMinSavings]= useState();
    const [maxSavings, setMaxSavings]= useState();
    const [isEarlySystem, setIsEarlySystem]= useState(false);
    const [exportRate, setExportRate]= useState();
    const [systemLifespan, setSystemLifespan]= useState(initialSystemLifeSpan);
    const [emailStatus, setEmailStatus]= useState('');

    const onSubmit = (year, productionRange, panelCount) => {
        if(year && productionRange){ 
            const { 
                isEarlySystem,
                yearsRemaining,
                firstLifeRemaining,
                secondLifeRemaining,
                minSavings,
                maxSavings,
                exportRate
             } = calculateSavings(year, productionRange);

            setYear(year);
            setPanels(panelCount);
            setIsEarlySystem(isEarlySystem);
            setExportRate(exportRate);

            setSystemLifespan({
                totalYears: yearsRemaining,
                firstLife: firstLifeRemaining,
                secondLife: secondLifeRemaining,
            });
            setMinSavings(minSavings);
            setMaxSavings(maxSavings);
        }
    }

    const submitRequestForInspection = async (
        name,
        address,
        phone,
        email,
    ) => {
        console.log(`Please contact me at ${phone} or ${email} system installation`);
        if(name && (phone || email)){
            console.log(`sending email:\n ${name}, y: ${year}, p: ${panels}, s: ${minSavings} - ${maxSavings}`);
            setEmailStatus(EMAIL_STATUS_SENDING);
            try{
                const resp= await fetch(
                    `${process.env.REACT_APP_URL}/.netlify/functions/emails/inspection`,
                    {
                        headers: {
                            "netlify-emails-secret": process.env.REACT_APP_NETLIFY_EMAILS_SECRET,
                        },
                        method: "POST",
                        body: JSON.stringify({
                            from: "info@solarevaluationservices.com",
                            to: ["chris@solarevaluationservices.com", "dave@solarevaluationservices.com"],
                            subject: "Inspection Report Request",
                            parameters: {
                                formData: {
                                    name,
                                    email,
                                    phone,
                                    address,
                                    year,
                                    panels,
                                    minSavings,
                                    maxSavings,
                                }
                            },
                        }),
                    }
                );

                setEmailStatus(EMAIL_STATUS_SUCCESS);
            }
            catch(e){
                setEmailStatus(EMAIL_STATUS_FAILURE);
                console.log(`Failed to send inspection request email: ${JSON.stringify(e)}`);
            }
        }
    }

    return (
        <Box 
            sx={{
                display: 'flex',
                flexDirection: 'column',
                background: systemLifespan?.totalYears ? 'rgba(255,255,255, 1)': 'rgba(0,0,0, 0.6)',
                borderRadius: 5,
                border: `solid 4px ${systemLifespan?.totalYears ? 'white' : 'black'}`,
                padding: '2px 18px',
                width: 'auto'
            }}
        >
            {systemLifespan?.totalYears && minSavings && maxSavings 
                ?  emailStatus === EMAIL_STATUS_SENDING 
                    ? <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding:30,
                            }}
                        >
                        <CircularProgress />
                    </div>
                    : emailStatus === EMAIL_STATUS_SUCCESS
                        ? <EmailSuccessMessage />
                        : emailStatus === EMAIL_STATUS_FAILURE
                        ? <EmailFailureMessage />
                        :  (
                        <>
                            <SystemEstimation 
                                minSavings={minSavings}
                                maxSavings={maxSavings}
                                systemLifespan={systemLifespan}
                                onSubmit={submitRequestForInspection}
                            />
                        </>
                    )
                : <CalculatorForm onSubmit={onSubmit}/>
            }
        </Box>
    )
}

const SystemEstimation = ({minSavings, maxSavings, systemLifespan, onSubmit}) => (
    <>
        <Typography
            fontFamily='Montserrat'
            fontSize={{ xs: 16, md: 20 }}
            color="black"
            textAlign="center"
            paddingTop={2}
        >
            Great News! Your solar system will save you an estimated<br />
            <span style={{ color: 'rgb(56,170,54)', fontSize: '1.4em' }}>{minSavings} - {maxSavings}</span><br />
            over the next {systemLifespan?.totalYears} years.
        </Typography>
            
        <hr style={{width: '80%'}} />
        <div style={{
            marginTop: 20,
        }}>
            <SubmissionForm onSubmit={onSubmit}/>
        </div>
    </>
    
)

const EmailSuccessMessage = () => (
    <Typography
        fontFamily='Montserrat'
        fontSize={{ xs: 18, md: 22 }}
        color="black"
        textAlign="center"
        paddingTop={2}
        paddingBottom={2}
    >
        Thanks! We'll be in touch soon.
    </Typography>
)

const EmailFailureMessage = () => (
    <>
        <Typography
            fontFamily='Montserrat'
            fontSize={{ xs: 16, md: 22 }}
            color="red"
            textAlign="center"
            paddingTop={2}
        >
            Oops. Something went wrong on our end. 
        </Typography>
        <Typography
            fontFamily='Montserrat'
            fontSize={{ xs: 12, md: 14 }}
            color="black"
            textAlign="center"
            paddingTop={2}
            paddingBottom={2}
        >
            Please try again, or email us at info@solarevaluationservices.com.
        </Typography>
    </>
)

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        background: 'rgba(0,0,0, 0.6)',
        borderRadius: 10,
        border: 'solid 4px black',
        padding: '2px 18px',
        width: 'auto'
    },
    row: {
       display: 'flex',
       flexDirection: 'row',
       justifyContent: 'space-between',
       marginBottom: '12px',
       margin: 'auto'
    },
    contact: {
        marginRight: 16,
    }
}


export default SolarSavingsCalculator;