
import './App.css';
import BorderContent from './BorderContent/BorderContent';
import CopyRight from './CopyRight/CopyRight';
import CallToAction from './CallToAction/CallToAction';
import Footer from './Footer/Footer';
import NavBar from './NavBar/NavBar';
import HeaderCard from './CardContent/HeaderCard';
import ContentCard from './CardContent/ContentCard';
import Tagline from './Tagline/Tagline';
import { useEffect, useState } from 'react';
import Loader from './Loader/Loader';
import FormInput from './FormInput/FormInput';
import Calculator from './Calculator/Calculator';

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="App">
      {loading ? ( 
      <Loader /> 
      ) : (
        <div>
      <section>
        <NavBar />
      </section>
      <section>
        <HeaderCard />
      </section>
      <section>
        <BorderContent />
      </section>
      <section>
        <ContentCard />
      </section>
      <section>
        <CallToAction />
      </section>
      <section>
        <FormInput />
      </section>
      <footer>
        <Footer />
      </footer>
      <section>
        <CopyRight />
      </section>
      </div>
      )}
    </div>
  );
}

export default App;
