import { Typography, Button } from '@mui/material'
import MailRoundedIcon from '@mui/icons-material/MailRounded';
import { Box } from '@mui/system'
import React from 'react'

const BorderContent = () => {
  return (
    <Box
    display='flex'
    flexDirection='column'
    alignItems='center'
    bgcolor='#080808'
    color='white'
    sx={{height: 250}} 
    >
    <Typography 
    fontFamily='Montserrat' 
    padding={1} 
    fontSize={{lg: 29, sm: 25}}
    fontWeight='bold' 
    textAlign='center'>
      Arizona has Hundreds of Thousands of Solar Powered Homes
    </Typography>
      <Typography
      fontFamily='Montserrat'
      fontWeight='bold'
      variant='h3'
      fontSize={{lg: 30, md: 24, sm: 20, xs: 18}}
        sx={{
        background: 'linear-gradient(to right, #8bda8a, #f8e09e)',
        'WebkitBackgroundClip': 'text',
        'WebkitTextFillColor': 'transparent',
      }}
      >
      Our Solar Professionals Will Evaluate Your Solar System
      </Typography>
          <Typography
      fontFamily='Montserrat'
      fontWeight='bold'
      variant='h3'
      fontSize={{lg: 30, md: 24, sm: 20, xs: 18}}
       sx={{
        background: 'linear-gradient(to left, #8bda8a, #f8e09e)',
        'WebkitBackgroundClip': 'text',
        'WebkitTextFillColor': 'transparent',
      }}
      >
      To Help Estimate The Full Value Of Your Home
      </Typography>
        <Button
              href={`mailto:chris@solarevaluationservices.com, dave@solarevaluationservices.com`}
              endIcon={<MailRoundedIcon />}
              variant='outlined'
              sx={{
                backgroundColor: '#000', 
                color: 'white',
                mt: 2.5,
                borderColor: '#fff'
              }}>
            Contact Us
          </Button>
    </Box>
  )
}

export default BorderContent
