import React, { useState } from 'react'
import { Box, Typography, TextField, Button } from '@mui/material'

const SubmissionForm = ({
    onSubmit
}) => {

    const [name, setName]= useState("");
    const [address, setAddress] = useState("");
    const [phone, setPhone]= useState("");
    const [email, setEmail]= useState("");

    const handleSubmitInspection = () => {
        if(name && (phone || email)){
            console.log(`- calling props.onSubmit`)
            onSubmit(name, address, phone, email);
        }
    }

    return (
        <Box>
            <Typography
                fontFamily='Montserrat'
                fontSize={{ lg: 20, md: 18, sm: 18, xs: 16 }}
                fontWeight='bold'
                color='black'
            >
                Contact us to schedule a full inspection and report.
            </Typography>
           
            <Box 
                display='flex'
                flexDirection={{ xs: 'column', md: 'row' }}
                justifyContent={'space-between'}
                gap={2}
                marginBottom={2}
            >
                <TextField 
                    id="name-text" 
                    label="Name" 
                    variant="standard" 
                    value={name} 
                    onChange={e=>setName(e.target.value)}
                />
                 <TextField 
                    id="address-text" 
                    label="Address (optional)" 
                    variant="standard" 
                    value={address}
                    onChange={e=>setAddress(e.currentTarget.value)}
                    sx={{
                        minWidth: '30%'
                    }}
                    />
                <TextField 
                    id="phone-text" 
                    label="Phone" 
                    variant="standard" 
                    value={phone}
                    width={{ xs: '100%', md: '14%'}}
                    onChange={e=>setPhone(e.currentTarget.value)}
                    
                />
                <TextField 
                    id="email-text" 
                    label="Email" 
                    variant="standard" 
                    email={email} 
                    onChange={e=>setEmail(e.currentTarget.value)}
                    sx={{
                        minWidth: '25%'
                    }}
                />
                
            </Box>
            <Box
                display='flex'
                flexDirection={'row'}
                justifyContent={'space-around'}
            >
                <Button 
                    variant='outlined'
                    onClick={handleSubmitInspection}
                    sx={{
                        borderColor: "rgb(56,170,54)",
                        borderWidth: 2,
                        backgroundColor: "white",
                        color: 'rgb(56,170,54)',
                        fontSize: 18,
                        fontWeight: 'bold',
                        '&:hover': {
                            borderColor: 'rgb(56,170,54)',
                            color: 'white',
                            borderWidth: 2,
                            backgroundColor: 'rgb(56,170,54)',
                        }
                    }}
                >
                    I want a full inspection
                </Button>
            </Box>
            
            <Typography
                fontFamily='Montserrat'
                fontSize={{ lg: 10, md: 10, sm: 8, xs: 8 }}
                color= 'rgb(100,100,100)'
                paddingTop={2}
                paddingBottom={1}
                fontStyle='italic'
            >
                *SES will never sell or share your information and will only contact you to speak to you about your inspection request. 
            </Typography>

            

        </Box>
    )
}

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'black',
        color: 'white',
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 10,
        borderRadius: 8,
    },
    row: {
       display: 'flex',
       flexDirection: 'row',
       justifyContent: 'space-between',
       marginBottom: '12px'
    },
}

export default SubmissionForm;